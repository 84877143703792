import type { InjectionKey } from 'vue'
import {
  ConnectionType,
  ContainerType,
  ContainerWatchState,
  PortalName,
  ShippingLine,
  TerminalName,
  UserRoleType,
} from './services/apiClient'
import type { ConnectionMetadata } from './models/connectionMetadata'

export const contentAreaHeightInjectionKey = Symbol(
  'containerAreaHeight'
) as InjectionKey<Ref<number>>

export const EDIT_APPT_ICON = 'mdi:calendar-edit'
export const NEEDS_APPT_ICON = 'mdi:calendar-question'
export const CANT_MAKE_APPT_ICON = 'mdi:calendar-remove'
export const APPT_ICON = 'mdi:calendar'
export const ADD_APPT_ICON = 'mdi:calendar-plus'
export const CONTAINER_ICON = 'clarity:container-line'
export const ON_VESSEL_ICON = 'mdi:ferry'
export const LOADED_CONTAINER_ICON = 'clarity:container-solid'
export const EMPTY_CONTAINER_ICON = 'clarity:container-line'
export const OUTGATE_ICON = 'ion:exit-outline'
export const INGATE_ICON = 'ion:enter-outline'
export const UNKNOWN_ICON = 'carbon:unknown'

export const DEMO_MODE = true && import.meta.env.DEV

export interface ContainerStatusFilter {
  label: string
  icon: string
  states: ContainerWatchState[]
}
export const AVAILABLE_STATUS_LABEL = 'Available'

export function getContainerStateIcon(state: ContainerWatchState): string {
  switch (state) {
    case ContainerWatchState.ImportTrackingOnVessel:
      return ON_VESSEL_ICON
    case ContainerWatchState.ImportTracking:
      return CANT_MAKE_APPT_ICON
    case ContainerWatchState.ImportApptBooking:
      return NEEDS_APPT_ICON
    case ContainerWatchState.ImportApptBooked:
      return APPT_ICON
    case ContainerWatchState.ImportPickedUp:
      return OUTGATE_ICON
    case ContainerWatchState.ImportEmptyApptBooked:
      return APPT_ICON
    case ContainerWatchState.ImportEmptyReturned:
      return INGATE_ICON
    case ContainerWatchState.ExportApptBooked:
      return APPT_ICON
    default:
      return UNKNOWN_ICON
  }
}

export function getContainerStateLabel(state: ContainerWatchState): string {
  switch (state) {
    case ContainerWatchState.ImportTrackingOnVessel:
      return 'On Vessel'
    case ContainerWatchState.ImportTracking:
      return 'Unavailable'
    case ContainerWatchState.ImportApptBooking:
      return AVAILABLE_STATUS_LABEL
    case ContainerWatchState.ImportApptBooked:
      return 'Appointed'
    case ContainerWatchState.ImportPickedUp:
      return 'Out-Gated'
    case ContainerWatchState.ImportEmptyApptBooked:
      return 'Empty Appointed'
    case ContainerWatchState.ImportEmptyReturned:
      return 'Terminated'
    case ContainerWatchState.Unknown:
      return 'Unknown Status'
    case ContainerWatchState.ExportApptBooked:
      return 'Export Appointment Booked'
    default:
      return state
  }
}

export const CONTAINER_STATES: ContainerStatusFilter[] = [
  {
    label: 'On Vessel',
    icon: ON_VESSEL_ICON,
    states: [ContainerWatchState.ImportTrackingOnVessel],
  },
  {
    label: 'Unavailable',
    icon: CANT_MAKE_APPT_ICON,
    states: [ContainerWatchState.ImportTracking],
  },
  {
    label: AVAILABLE_STATUS_LABEL,
    icon: NEEDS_APPT_ICON,
    states: [ContainerWatchState.ImportApptBooking],
  },
  {
    label: 'Appointed',
    icon: APPT_ICON,
    states: [
      ContainerWatchState.ImportApptBooked,
      ContainerWatchState.ImportEmptyApptBooked,
    ],
  },
  {
    label: 'Out-Gated',
    icon: OUTGATE_ICON,
    states: [ContainerWatchState.ImportPickedUp],
  },
  {
    label: 'Terminated',
    icon: INGATE_ICON,
    states: [ContainerWatchState.ImportEmptyReturned],
  },
  {
    label: 'Unknown',
    icon: UNKNOWN_ICON,
    states: [ContainerWatchState.Unknown],
  },
]

export const CONTAINER_DISPLAY_STATES_BY_STATE = new Map() as Map<
  ContainerWatchState,
  ContainerStatusFilter
>
for (const entry of CONTAINER_STATES) {
  for (const state of entry.states)
    CONTAINER_DISPLAY_STATES_BY_STATE.set(state, entry)
}

export const TERMINALS: { name: TerminalName; label: string }[] = [
  { name: TerminalName.Apm, label: 'APM' },
  { name: TerminalName.Everport, label: 'Everport' },
  { name: TerminalName.Fms, label: 'FMS' },
  { name: TerminalName.Lbct, label: 'LBCT' },
  // { name: TerminalName.MatsonLb, label: "Matson" },
  { name: TerminalName.Pct, label: 'PCT' },
  { name: TerminalName.PierA, label: 'Pier A' },
  { name: TerminalName.Trapac, label: 'Trapac' },
  { name: TerminalName.Tti, label: 'TTI' },
  { name: TerminalName.Wbct, label: 'WBCT' },
  { name: TerminalName.Yti, label: 'YTI' },
  { name: TerminalName.Its, label: 'ITS' },
  { name: TerminalName.MatsonLb, label: 'Matson (C60)' },
  { name: TerminalName.StAlam, label: 'Shippers Alameda' },
  { name: TerminalName.StLb, label: 'Shippers Long Beach' },
  { name: TerminalName.StCarson, label: 'Shippers Carson' },
]

export const LA_TERMINALS: TerminalName[] = [
  TerminalName.Apm,
  TerminalName.Everport,
  TerminalName.Fms,
  TerminalName.Trapac,
  TerminalName.Wbct,
  TerminalName.Yti,
]

const all_terminals = new Set(TERMINALS.map((t) => t.name))

export const TERMINALS_REQUIRING_MASTER_BL = new Set<TerminalName>([
  // TerminalName.Everport, // We pre-fill "internally"
  // TerminalName.Its, // We pre-fill "internally"
  // TerminalName.Fms, // We pre-fill "internally"
])
export const TERMINALS_REQUIRING_MASTER_BL_FOR_UAS = new Set<TerminalName>([
  TerminalName.Fms,
])
export const TERMINALS_WHERE_LICENSE_PLATE_CAN_BE_EDITED =
  new Set<TerminalName>([
    TerminalName.Apm,
    TerminalName.Pct,
    TerminalName.PierA,
    TerminalName.Its,
    TerminalName.Everport,
    TerminalName.Lbct,
    TerminalName.Fms,
    TerminalName.Trapac,
    // TTI can be edited because it uses eModal now
    TerminalName.Tti,
  ])

export const TERMINALS_WITH_CHASSIS_OPTION_FOR_EMPTY_APPOINTMENT =
  new Set<TerminalName>([TerminalName.Its, TerminalName.Everport])

export const TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN = new Set<TerminalName>([
  TerminalName.Its,
  TerminalName.Pct,
  TerminalName.PierA,
])
export const TERMINAL_NOT_REQUIRING_APPOINTMENTS = new Set<TerminalName>([
  TerminalName.StAlam,
  TerminalName.StLb,
  TerminalName.StCarson,
  TerminalName.MatsonLb,
])
export const TERMINALS_REQUIRING_APPOINTMENTS = new Set<TerminalName>(
  Array.from(all_terminals).filter(
    (terminal) => !TERMINAL_NOT_REQUIRING_APPOINTMENTS.has(terminal)
  )
)

export const TERMINALS_REQUIRING_LICENSE_PLATE = new Set<TerminalName>([
  // TerminalName.Its, # ITS longer requires license plate
  TerminalName.Trapac,
])
export const TERMINALS_REQUIRING_LICENSE_PLATE_FOR_EMPTY_APPOINTMENT =
  new Set<TerminalName>([TerminalName.Its, TerminalName.Trapac])
export const TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING =
  new Set<TerminalName>([TerminalName.Wbct, TerminalName.Yti])
export const TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING_EMPTY_APPOINTMENTS =
  new Set<TerminalName>([TerminalName.Wbct, TerminalName.Fms, TerminalName.Yti])

export const TERMINALS_USING_OWN_CHASSIS_DESIGNATION = new Set<TerminalName>([
  TerminalName.Apm,
])
export const TERMINALS_TO_ALLOW_AUTOMATIC_PLATE_SETTING = new Set<TerminalName>(
  [TerminalName.Apm]
)
export const TERMINALS_WHERE_LICENSE_PLATE_IS_OPTIONAL_USED_WHEN_BOOKING =
  new Set<TerminalName>()
for (const terminal of all_terminals) {
  if (
    !TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING.has(terminal) &&
    !TERMINALS_REQUIRING_LICENSE_PLATE.has(terminal) &&
    !TERMINAL_NOT_REQUIRING_APPOINTMENTS.has(terminal)
  ) {
    TERMINALS_WHERE_LICENSE_PLATE_IS_OPTIONAL_USED_WHEN_BOOKING.add(terminal)
  }
}

export const SHIPPERS_TERMINALS = new Set<TerminalName>([
  TerminalName.StAlam,
  TerminalName.StLb,
  TerminalName.StCarson,
])

export const EMODAL_TERMINALS = new Set<TerminalName>([
  TerminalName.Everport,
  TerminalName.Trapac,
  TerminalName.Pct,
  TerminalName.PierA,
  TerminalName.Its,
  TerminalName.MatsonLb,
])

export const TERMINALS_NOT_REQUIRING_EMPTY_APPOINTMENTS_FOR_DUALS =
  new Set<TerminalName>([TerminalName.Trapac, TerminalName.Yti])

const TERMINAL_LOOKUP = new Map(TERMINALS.map((t) => [t.name, t.label]))
export function getTerminalLabel(terminal: TerminalName): string {
  return TERMINAL_LOOKUP.get(terminal) || terminal
}
const PORTAL_NAMES = new Map<PortalName, string>([
  [PortalName.Apm, 'APM'],
  [PortalName.ApmEir, 'APM EIR'],
  [PortalName.ApmTops, 'APM TOPS'],
  [PortalName.ApmTermpoint, 'APM Termpoint'],
  [PortalName.ApmTermpointApi, 'APM Termpoint API'],
  [PortalName.Emodal, 'eModal'],
  [PortalName.Everport, 'Everport'],
  [PortalName.FmsCommunityPortal, 'FMS Community Portal'],
  [PortalName.FmsVoyageControls, 'FMS Voyage Controls'],
  [PortalName.FmsGoogle, 'FMS'],
  [PortalName.Lbct, 'LBCT'],
  [PortalName.LbctApi, 'LBCT'],
  [PortalName.PctTideworks, 'PCT'],
  [PortalName.PierATideworks, 'Pier A'],
  [PortalName.Trapac, 'Trapac'],
  [PortalName.Tti, 'TTI'],
  [PortalName.TtiNoLogin, 'TTI'],
  [PortalName.Wbct, 'WBCT'],
  [PortalName.Yti, 'YTI'],
  [PortalName.YtiLynx, 'YTI Lynx'],
  [PortalName.YtiVoyageControls, 'YTI Voyage Controls'],
  [PortalName.YtiGateLink, 'YTI Gate Link'],
  [PortalName.Its, 'ITS'],
  [PortalName.ItsNoLogin, 'ITS'],
  [PortalName.ShippersExpressTideworks, 'Shippers Express'],
  [PortalName.ShippersLongbeachTideworks, 'Shippers Long Beach'],
  [PortalName.PortOptimizer, 'Port Optimizer'],
  [PortalName.PortOptimizerApi, 'Port Optimizer API'],
  [PortalName.FmsApi, 'FMS API'],
  [PortalName.TrapacApi, 'TraPac'],
  [PortalName.WbctApi, 'WBCT API'],
])
export function getPortalLabel(portal: PortalName): string {
  return PORTAL_NAMES.get(portal) || portal
}
export function isInternalUser(email: string): boolean {
  return email.endsWith('@draydog.com') || email.endsWith('@wabtec.com')
}
export const CRITICAL_CONNECTION_TYPES = [
  // connection types we want to ensure are set up and will show a banner if not
  // set up
  ConnectionType.One,
]
export const TMS_CONNECTION_TYPES = [
  {
    type: ConnectionType.TriniumSftp,
    label: 'Trinium SFTP',
  },
  {
    type: ConnectionType.BlackflyConnect,
    label: 'Profit Tools',
  },
]

export const EDITABLE_CONNECTION_TYPES: ConnectionMetadata[] = [
  // Container tracking only connections
  {
    type: ConnectionType.PierA,
    label: 'Pier A',
    url: 'https://piera.tideworks.com',
  },
  {
    type: ConnectionType.Pct,
    label: 'PCT',
    url: 'https://pct.tideworks.com/fc-PCT/default.do',
  },
  {
    type: ConnectionType.ShippersLongbeachTideworks,
    label: 'Shippers Long Beach',
    url: 'https://sta.tideworks.com/fc-STA/default.do',
  },
  {
    type: ConnectionType.ShippersExpressTideworks,
    label: 'Shippers Express',
    url: 'https://stl.tideworks.com/fc-STL/default.do',
  },
  {
    type: ConnectionType.PierPass,
    label: 'Pier Pass',
    url: 'https://www.pierpass-tmf.org/',
  },
  {
    type: ConnectionType.YtiLynx,
    label: 'YTI Lynx',
    url: 'https://lynx.yti.com/',
  },
  {
    type: ConnectionType.Its,
    label: 'ITS',
    url: 'https://www.itslb.com',
  },
  {
    type: ConnectionType.Everport,
    label: 'Everport',
    url: 'https://www.etslink.com/',
  },
  {
    type: ConnectionType.Tti,
    label: 'TTI',
    url: 'https://www.ttilgb.com',
  },
  {
    type: ConnectionType.ApmTops,
    label: 'APM TOPS',
    url: 'https://tops.apmterminals.com',
  },
  // Appointment systems
  {
    type: ConnectionType.Wbct,
    label: 'WBCT',
    url: 'https://voyagertrack.portsamerica.com',
  },
  {
    type: ConnectionType.ApmTermpoint,
    label: 'APM Termpoint',
    url: 'https://termpoint.apmterminals.com',
  },
  {
    type: ConnectionType.Emodal,
    label: 'Emodal',
    url: 'https://www.emodal.com',
  },
  {
    type: ConnectionType.YtiGateLink,
    label: 'YTI Gate Link',
    url: 'http://www.yti.com/GateLink',
  },
  // { type: ConnectionType.Lbct, label: 'LBCT', url: 'https://www.lbct.com', note: '' },
  // {
  //   type: ConnectionType.LbctApi,
  //   label: 'LBCT API',
  //   url: 'https://www.lbct.com',
  //  note: ''
  // },
  {
    type: ConnectionType.FmsCommunityPortal,
    label: 'FMS Community Portal',
    url: 'https://portal.fenixmarineservices.com',
  },
  // lines
  {
    type: ConnectionType.One,
    label: 'ONE Line',
    url: 'https://ecomm.one-line.com/ecom/SPP_COM_1001.do',
  },
  // {
  //   type: ConnectionType.FmsApi,
  //   label: 'FMS API (beta/optional)',
  //   url: 'https://fenixmarineservices.com/api-connectivity/',
  // note: ''
  // },
]

export const UAS_EDITABLE_CONNECTION_TYPES: ConnectionMetadata[] = [
  // LA non-emodal
  {
    type: ConnectionType.Wbct,
    label: 'WBCT',
    url: 'https://voyagertrack.portsamerica.com',
  },
  {
    type: ConnectionType.ApmTermpoint,
    label: 'APM Termpoint',
    url: 'https://termpoint.apmterminals.com',
    note: 'Used for creating dual appointments.',
  },
  {
    type: ConnectionType.ApmTermpointApi,
    label: 'APM Termpoint API',
    url: 'https://termpoint.apmterminals.com/Account/ManageUsers',
    note: 'To get an API key, go to https://termpoint.apmterminals.com, navigate to "Users and Groups -> Manage Users".  Click "edit" on your user, then click Manage API key and copy the "Authentication Key."',
  },
  {
    type: ConnectionType.YtiGateLink,
    label: 'YTI Gate Link',
    url: 'http://www.yti.com/GateLink',
  },
  {
    type: ConnectionType.FmsCommunityPortal,
    label: 'FMS Community Portal',
    url: 'https://portal.fenixmarineservices.com',
  },
]
export const UAS_EDITABLE_CONNECTION_TYPES_SET = new Set<ConnectionType>(
  UAS_EDITABLE_CONNECTION_TYPES.map((c) => c.type)
)
const CONTAINER_TYPE_DESCRIPTIONS = new Map<ContainerType, string>([
  [ContainerType._40Hc, '40ft High Cube'],
  [ContainerType._40Sd, '40ft Standard'],
  [ContainerType._45Hc, '45ft High Cube'],
  [ContainerType._20Sd, '20ft Standard'],
  [ContainerType._20HcReefer, '20ft Reefer'],
  [ContainerType._40HcReefer, '40ft Reefer'],
  [ContainerType._45HcReefer, '45ft Reefer'],
  [ContainerType._45Ot, '45ft Open Top'],
])

export function getContainerTypeDescription(
  containerType: ContainerType
): string {
  return CONTAINER_TYPE_DESCRIPTIONS.get(containerType) || containerType
}

export const SELF_SELECTABLE_USER_ROLES = [
  UserRoleType.CustomerService,
  UserRoleType.Dispatch,
  UserRoleType.Management,
  UserRoleType.Other,
]

// These are lines that might still show up in data/are useful historically but
// are no longer active
export const DEFUNCT_SHIPPING_LINES = new Set<ShippingLine>([
  ShippingLine.Aplu,
  ShippingLine.Banr,
  ShippingLine.Culu,
  ShippingLine.Llhp,
  // ShippingLine.Sqqy,
  ShippingLine.Tgr,
  ShippingLine.Ymlu, // Replaced by Ymja
])

export function isValidShippingLine(value: any): value is ShippingLine {
  return Object.values(ShippingLine).includes(value)
}
export function isValidContainerType(value: any): value is ContainerType {
  return Object.values(ContainerType).includes(value)
}
export function isValidTerminalName(value: any): value is TerminalName {
  return Object.values(TerminalName).includes(value)
}

export const TERMS_AND_CONDITIONS_LINK =
  'https://drive.google.com/file/d/11U70ik1qVrqimEO-KlwCHvzxNqh7oH0b/view?usp=sharing'

export const CONTAINER_ROW_MIN_WIDTH_PIXELS = 570
export const TRUCK_STATUS_DAYS_TO_LOAD = 30

export function getAppointmentSystemConnectionTypes(
  terminal: TerminalName
): ConnectionType[] | null {
  // Multiple types return when there are multiple suitable options for a terminal
  switch (terminal) {
    case TerminalName.StAlam:
    case TerminalName.StLb:
    case TerminalName.StCarson:
    case TerminalName.MatsonLb:
      return null
    case TerminalName.Wbct:
      return [ConnectionType.Wbct]
    case TerminalName.Apm:
      return [ConnectionType.ApmTermpoint, ConnectionType.ApmTermpointApi]
    case TerminalName.Yti:
      return [ConnectionType.YtiGateLink]
    case TerminalName.Fms:
      return [ConnectionType.FmsCommunityPortal]
    case TerminalName.Lbct:
      return [ConnectionType.LbctApi]
    case TerminalName.Tti:
    case TerminalName.Pct:
    case TerminalName.Its:
    case TerminalName.Everport:
    case TerminalName.PierA:
    case TerminalName.Trapac:
      return [ConnectionType.Emodal]
  }
}
