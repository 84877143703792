<script setup lang="ts">
import ShiftSlots from './ShiftSlots.vue'
import LoadingDots from './LoadingDots.vue'
import BookingStatusTd from './BookingStatusTd.vue'
import type { HasAppointmentWindow } from './miscTypes'
import type { ImportStatusBase } from '~~/models/importStatuses'
import ContainerInfoTd from '~~/components/ContainerInfoTd.vue'
import { useLoadSlots } from '~~/compositions/loadSlots'
import BookingDialog from '~~/components/dialogs/BookingDialog.vue'
import Spinner from '~~/components/Spinner.vue'

import {
  groupSlotsIntoShiftBuckets,
  makeShiftKey,
} from '~~/logic/slotBucketing'
import type { TerminalName } from '~/services/apiClient'
import { ImportLocation, Shift } from '~/services/apiClient'
import type { SlotSelectedEvent } from '~/components/appointmentSlots/events'
import type { BookingRequestState } from '~~/stores/mutationRequests'
import { useMutationRequestsStore } from '~~/stores/mutationRequests'
import { useAppointmentWithContainerInfoStore } from '~/stores/appointmentsWithContainerInfo'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import type { Appointment } from '~/models/appointments'
import { makeDummyAppointmentSlot } from '~/models/appointmentSlot'
import { getTerminalLabel } from '~/constants'
import { useConnectionsStore } from '~/stores/connections'

const props = defineProps<{
  importStatus: ImportStatusBase | undefined
  existingAppointment: Appointment | undefined
  showContainersColumn: boolean
  supportedTerminals?: TerminalName[]
  loadSlotsImmediately: boolean
}>()

const emit = defineEmits<{
  (e: 'bookingBegun', event: BookingRequestState): void
}>()

const inProgressBookingRequest = ref<BookingRequestState | null>(null)
const loadSlotsButtonClicked = ref(false)
const terminal = computed(() => {
  if (props.importStatus) {
    return props.importStatus.terminal
  } else if (props.existingAppointment) {
    return props.existingAppointment.terminal
  } else {
    throw new Error(
      'No import status or existing appointment, this should not be'
    )
  }
})
const terminalBlockAvailabilityKey = computed((): string | null => {
  if (props.importStatus) {
    return props.importStatus.terminal_block_availability_key
  } else {
    return null
  }
})
const containerNumber = computed(() => {
  if (props.importStatus) {
    return props.importStatus.number
  } else if (props.existingAppointment) {
    return props.existingAppointment.container_number
  } else {
    throw new Error(
      'No import status or existing appointment, this should not be'
    )
  }
})

const {
  load: loadSlots,
  datesToShow,
  slots,
  loading: loadingSlots,
} = useLoadSlots(
  containerNumber.value,
  terminal.value,
  terminalBlockAvailabilityKey.value
)

const mutationRequestsStore = useMutationRequestsStore()
const appointmentsWithContainersStore = useAppointmentWithContainerInfoStore()

const slotSelection = ref<SlotSelectedEvent | null>(null)

const bookedAppointment = ref<AppointmentWithContainerInfo | undefined>(
  undefined
)

const currentAppointmentWindow = computed((): HasAppointmentWindow | null => {
  if (bookedAppointment.value) {
    return {
      window_start: bookedAppointment.value.appointment.window_start,
      window_end: bookedAppointment.value.appointment.window_end ?? null,
    }
  } else if (inProgressBookingRequest.value) {
    return {
      window_start: inProgressBookingRequest.value.selectedSlot.window_start,
      window_end:
        inProgressBookingRequest.value.selectedSlot.window_end ?? null,
    }
  } else {
    return null
  }
})

const headerColspan = computed(() => {
  // If we have appointment window to show in the left-most column, we span the first
  // displayed day, which is two columns for a colspan of 3
  return currentAppointmentWindow.value ? 3 : 1
})
const mainColspan = computed(() => {
  return datesToShow.value.length * 2 - headerColspan.value + 1
})
const blockBookingError = computed(() => {
  if (
    props.supportedTerminals &&
    !props.supportedTerminals.includes(terminal.value)
  ) {
    return `Booking at ${getTerminalLabel(terminal.value)} is not supported`
  }
  if (props.importStatus) {
    if (!props.importStatus.ready_for_appointment) {
      return 'Container not ready for appointment'
    }
    if (props.importStatus.parsed_location !== ImportLocation.InTerminal) {
      return `Container not in terminal (${props.importStatus.parsed_location})`
    }
  }
})
const appointmentSystemSetup = ref(false)
watch(
  terminal,
  async () => {
    appointmentSystemSetup.value =
      await useConnectionsStore().isAppointmentSystemConnectionSetup(
        terminal.value
      )
  },
  { immediate: true }
)
const canBook = computed(() => {
  if (!appointmentSystemSetup.value) return false
  return blockBookingError.value === undefined
})

const shouldLoadSlots = computed(() => {
  if (!canBook.value) return false
  return props.loadSlotsImmediately || loadSlotsButtonClicked.value
})
function loadSlotsIfReady() {
  if (!shouldLoadSlots.value) {
    return
  }
  loadSlots()
}
watch([shouldLoadSlots, canBook], loadSlotsIfReady, { immediate: true })

// Keep our in-progress booking request up to date with the mutation store
watch(mutationRequestsStore.bookingRequestStatesByRequestID, (newVal) => {
  if (!inProgressBookingRequest.value) return
  const state = newVal.get(inProgressBookingRequest.value?.requestID)
  if (state) {
    inProgressBookingRequest.value = state
    computeBookedAppointmentIfNotSet()
  }
})

const slotsWithDummyAppointmentSlots = computed(() => {
  // No appointment, nothing to do
  if (!props.existingAppointment) return slots.value
  // If we have a slot for the appointment, set the appointment on that slot
  for (const slot of slots.value) {
    if (slot.window_start.equals(props.existingAppointment.window_start)) {
      slot.booked_appointment = props.existingAppointment
      return slots.value
    }
  }
  // Otherwise, add a dummy slot for the appointment
  const dummyAppointmentSlot = makeDummyAppointmentSlot(
    props.existingAppointment
  )
  return [...slots.value, dummyAppointmentSlot]
})

const shiftBuckets = computed(() => {
  const start = datesToShow.value[0].startOf('day')
  const end = datesToShow.value[datesToShow.value.length - 1]
    .endOf('day')
    .plus({ hours: 7 }) // Get us to the end of the third shift
  const shiftBuckets = groupSlotsIntoShiftBuckets(
    slotsWithDummyAppointmentSlots.value,
    start,
    end
  )
  return shiftBuckets
})

function computeBookedAppointmentIfNotSet() {
  if (bookedAppointment.value) return
  const bookingRequestLinkedAppointmentID =
    inProgressBookingRequest.value?.appointmentID
  if (!bookingRequestLinkedAppointmentID) return
  const appointment = appointmentsWithContainersStore.appointmentsByID.get(
    bookingRequestLinkedAppointmentID
  )
  if (appointment) {
    bookedAppointment.value = appointment
  }
}

watch(
  appointmentsWithContainersStore.appointmentsByID,
  computeBookedAppointmentIfNotSet
)

// Callback/step handlers, in order
function onSlotSelected(event: SlotSelectedEvent) {
  slotSelection.value = event
}
function onBookingStarted(event: BookingRequestState) {
  slotSelection.value = null
  inProgressBookingRequest.value = event
  emit('bookingBegun', event)
}
</script>

<template>
  <tr :ariaLabel="containerNumber">
    <ContainerInfoTd
      v-if="showContainersColumn"
      :import-status="props.importStatus"
      :container-number="containerNumber"
      :terminal="terminal"
      :appointment-window="currentAppointmentWindow"
      :colspan="headerColspan"
    />
    <BookingStatusTd
      v-if="inProgressBookingRequest"
      :colspan="mainColspan"
      :booking-request-state="inProgressBookingRequest"
      :booked-appointment="bookedAppointment"
    />
    <!-- We must set a height on the row for the h-full on the inner div to work -->
    <td
      v-else-if="loadingSlots"
      :colspan="mainColspan"
      class="align-top text-gray-600 text-lg bg-yellow-50"
      style="height: 1px"
    >
      <div class="w-full h-full flex items-center">
        <Spinner :width="50" class="mr-1" />
        Loading available times<LoadingDots />
      </div>
    </td>
    <td
      v-else-if="blockBookingError"
      :colspan="mainColspan"
      class="text-red-700 text-center"
    >
      <i-mdi:alert-circle-outline class="align-text-bottom" />
      {{ blockBookingError }}
    </td>
    <td
      v-else-if="!appointmentSystemSetup"
      :colspan="mainColspan"
      class="text-center"
    >
      <span class="text-red-700"> Appointment system not setup. </span>
      <p class="text-gray-600">
        Please configure in
        <RouterLink to="/settings" class="underline">Settings</RouterLink>
      </p>
    </td>
    <td
      v-else-if="!shouldLoadSlots"
      :colspan="mainColspan"
      class="text-gray-600 text-lg bg-yellow-50"
    >
      <div class="flex justify-center items-center h-full">
        <ElButton type="success" @click="loadSlotsButtonClicked = true">
          Load available times
        </ElButton>
      </div>
    </td>
    <template
      v-for="date of datesToShow"
      v-else-if="canBook"
      :key="date.toISODate()"
    >
      <!-- First shift -->
      <td class="align-top">
        <ShiftSlots
          :shift-bucket="shiftBuckets.get(makeShiftKey(date, Shift.First))"
          :existing-appointment="props.existingAppointment"
          @book="onSlotSelected"
        />
      </td>
      <!-- Second/third shift -->
      <td class="align-top bg-gray-200">
        <ShiftSlots
          :shift-bucket="shiftBuckets.get(makeShiftKey(date, Shift.Second))"
          :existing-appointment="props.existingAppointment"
          @book="onSlotSelected"
        />
        <ShiftSlots
          :shift-bucket="shiftBuckets.get(makeShiftKey(date, Shift.Third))"
          :existing-appointment="props.existingAppointment"
          @book="onSlotSelected"
        />
      </td>
    </template>

    <BookingDialog
      v-if="slotSelection"
      :appointment-slot="slotSelection.slot"
      :import-status="props.importStatus"
      :container-number="containerNumber"
      :terminal="terminal"
      :existing-appointment="props.existingAppointment"
      :append-to-body="true"
      @close="slotSelection = null"
      @booking-begun="onBookingStarted"
    />
  </tr>
</template>
