import { useRouteQuery } from '@vueuse/router'
import { controlledComputed, createGlobalState } from '@vueuse/core'

import type {
  ContainerType,
  ContainerWatchState,
  SavedCustomer,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient/api'
import {
  AVAILABLE_STATUS_LABEL,
  CONTAINER_STATES,
  TERMINALS,
  isValidContainerType,
  isValidShippingLine,
} from '~/constants'
import { useContainerStore } from '~/stores/containers'
import { useCustomerStore } from '~/stores/customers'

interface StateFilter {
  label: string
  icon: string
  states: ContainerWatchState[]
}

export interface ContainerFilters {
  customer_id?: number | undefined
  auto_book_on?: boolean | undefined
  auto_book_off?: boolean | undefined
  assigned_user_ids?: number[] | undefined
  container_types?: ContainerType[] | undefined
  shipping_lines?: ShippingLine[] | undefined
  updated_in_last_minutes?: number | undefined
  tags?: string[]
  // Optional filters
  container_numbers?: string
  states?: ContainerWatchState[]
  terminal?: TerminalName
  vessels?: string[]
}

// State variables
const STATUS_PARAM_NAME = 'status'
const useURLParams = createGlobalState(() =>
  reactive({
    terminal: useRouteQuery<TerminalName | undefined>('terminal'),
    vessels: useRouteQuery<string[] | undefined>('vessels', undefined),
    statusFilterLabel: useRouteQuery<string | undefined>(STATUS_PARAM_NAME),
    customerID: useRouteQuery<string | undefined>('customer_id', undefined),
    autoBookFilter: useRouteQuery<string | undefined>('auto_book_on'),
    autoBookOffFilter: useRouteQuery<string | undefined>('auto_book_off'),
    containersFilter: useRouteQuery<string | undefined>('containers'),
    assignedToFilter: useRouteQuery<string | undefined>('assigned_to'),
    tags: useRouteQuery<string | string[], string[]>('tags', undefined, {
      transform: (value) => {
        if (!value) {
          return []
        }
        if (Array.isArray(value)) {
          return value
        } else {
          return [value]
        }
      },
    }),
    containerTypeFilter: useRouteQuery<
      null | string | string[],
      ContainerType | undefined
    >('container_type', undefined, {
      transform: (value) => {
        if (!value) {
          return
        }
        const rawContainerType = Array.isArray(value) ? value[0] : value
        if (isValidContainerType(rawContainerType)) {
          return rawContainerType as ContainerType
        }
      },
    }),
    shippingLineFilter: useRouteQuery<
      null | string | string[],
      ShippingLine | undefined
    >('shipping_line', undefined, {
      transform: (value) => {
        if (!value) {
          return
        }
        const rawShippingLine = Array.isArray(value) ? value[0] : value
        if (isValidShippingLine(rawShippingLine)) {
          return rawShippingLine as ShippingLine
        }
      },
    }),
    updatedInLastMinutes: useRouteQuery<null | string, number | undefined>(
      'updated_in_last_minutes',
      undefined,
      {
        transform: (value) => {
          if (!value) {
            return undefined
          }
          const parsedValue = parseInt(value, 10)
          return isNaN(parsedValue) ? undefined : parsedValue
        },
      }
    ),
    page: useRouteQuery<string | undefined>('page'),
    // NOTE: If you add a new filter, make sure to add it to clearNonContainerSearchFilters
  })
)

const statusFilterOptions = ref(CONTAINER_STATES as StateFilter[])

function _useContainerFilters() {
  const filters = useURLParams()
  function clearNonContainerSearchFilters() {
    filters.terminal = undefined
    filters.customerID = undefined
    filters.autoBookFilter = undefined
    filters.autoBookOffFilter = undefined
    filters.assignedToFilter = undefined
    filters.shippingLineFilter = undefined
    filters.containerTypeFilter = undefined
    filters.updatedInLastMinutes = undefined
    filters.page = undefined
  }
  const terminalFilter = toRef(filters, 'terminal')
  const containerSearch = toRef(filters, 'containersFilter')
  // Computed values
  const terminalFilterLabel = computed(() => {
    for (const option of TERMINALS) {
      if (option.name === filters.terminal) {
        return option.label
      }
    }
    return ''
  })

  const customerIDFilter = computed(() => {
    if (!filters.customerID) {
      return undefined
    }
    return parseInt(filters.customerID, 10)
  })

  const page = computed(() => {
    if (!filters.page) {
      return 1
    }
    return parseInt(filters.page)
  })
  const pageSize = ref(1000)

  const customerFilter = computed((): SavedCustomer | undefined => {
    if (!customerIDFilter.value) {
      return undefined
    }
    const customersStore = useCustomerStore()
    return customersStore.getByCustomerID(customerIDFilter.value)
  })

  const autoBookOnFilter = computed(() => {
    return filters.autoBookFilter === 'true'
  })
  const autoBookOffFilter = computed(() => {
    return filters.autoBookOffFilter === 'true'
  })
  const statusFilter = computed(() => {
    for (const state of CONTAINER_STATES) {
      if (state.label === filters.statusFilterLabel) {
        return state
      }
    }
    return undefined
  })
  const statusFilterLabel = toRef(filters, 'statusFilterLabel')
  const route = useRoute()
  const router = useRouter()
  const onContainersPage = computed(() => {
    return route.name === 'containers'
  })

  // Interactions between container search and statuses. Basically when you search for
  // containers it turns off the status filters but you can then turn them back on if
  // you like
  onMounted(async () => {
    if (!statusFilterLabel.value && !containerSearch.value) {
      // Need to replace manually rather than set `filters.statusFilterLabel` because
      // otherwise the change will be completed asynchronously and
      // filters.statusFilterLabel will not be what we just set
      await router.replace({
        query: {
          ...route.query,
          [STATUS_PARAM_NAME]: AVAILABLE_STATUS_LABEL,
        },
      })
      if (filters.statusFilterLabel !== AVAILABLE_STATUS_LABEL) {
        throw new Error(
          `Expected filters.statusFilterLabel=${AVAILABLE_STATUS_LABEL}, but got ${filters.statusFilterLabel}`
        )
      }
    }
    loadStateCounts()
    loadByTerminalCounts()
    loadContainers()
  })

  watch(containerSearch, (newValue: any, oldValue: any) => {
    if (newValue && !oldValue) {
      // Started searching by container
      statusFilterLabel.value = 'Searched'
      clearNonContainerSearchFilters()
    } else if (!newValue && oldValue) {
      // Stopped searching by container
      if (!statusFilterLabel.value || statusFilterLabel.value === 'Searched') {
        statusFilterLabel.value = 'Available'
      }
    } else if (
      !newValue &&
      (!statusFilterLabel.value || statusFilterLabel.value === 'Searched')
    ) {
      statusFilterLabel.value = 'Available'
    }
  })

  const assignedToFilter = controlledComputed(
    () => filters.assignedToFilter,
    () => {
      if (filters.assignedToFilter) {
        const rawIDs = filters.assignedToFilter.split(',')
        const ids = rawIDs.map((id) => parseInt(id, 10))
        for (const id of ids) {
          if (id === -1) {
            return [-1]
          }
        }
        return ids
      } else {
        return []
      }
    }
  )
  const allFilters = computed((): ContainerFilters => {
    let assigned_user_ids = undefined as undefined | number[]
    if (assignedToFilter.value && assignedToFilter.value.length > 0) {
      assigned_user_ids = assignedToFilter.value
    }

    return {
      customer_id: customerIDFilter.value || undefined,
      auto_book_on: autoBookOnFilter.value || undefined,
      auto_book_off: autoBookOffFilter.value || undefined,
      shipping_lines: filters.shippingLineFilter
        ? [filters.shippingLineFilter]
        : undefined,
      container_types: filters.containerTypeFilter
        ? [filters.containerTypeFilter]
        : undefined,
      updated_in_last_minutes: filters.updatedInLastMinutes || undefined,
      assigned_user_ids,
      tags: filters.tags || undefined,
      container_numbers: containerSearch.value || undefined,
      states: statusFilter.value ? statusFilter.value.states : undefined,
      terminal: terminalFilter.value || undefined,
      vessels: filters.vessels || undefined,
    }
  })
  const filtersWithoutTerminal = computed((): ContainerFilters => {
    return {
      ...allFilters.value,
      terminal: undefined,
    }
  })
  const filtersWithoutStates = computed((): ContainerFilters => {
    return {
      ...allFilters.value,
      states: undefined,
    }
  })

  // Setters
  function setAutoBookOnFilter(value: boolean) {
    if (value) {
      filters.autoBookOffFilter = undefined
    }
    filters.autoBookFilter = value ? 'true' : undefined
  }
  function setAutoBookOffFilter(value: boolean) {
    if (value) {
      filters.autoBookFilter = undefined
    }
    filters.autoBookOffFilter = value ? 'true' : undefined
  }
  function setCustomerIDFilter(value: number | undefined | null) {
    filters.customerID =
      value === null || value === undefined ? undefined : value.toString()
  }
  function setAssignedToFilter(value: number[] | null) {
    if (value) {
      filters.assignedToFilter = value.join(',')
    } else {
      filters.assignedToFilter = ''
    }
  }
  function setPageNoReload(value: number) {
    const strValue = value.toString()
    if (filters.page !== strValue) {
      filters.page = value.toString()
    }
  }
  function setPage(value: number) {
    setPageNoReload(value)
    loadContainers()
  }
  // Stores
  const containerStore = useContainerStore()

  // Loading functions
  function loadContainers() {
    if (!onContainersPage.value) {
      return
    }
    containerStore.load({
      ...allFilters.value,
      page: page.value,
      pageSize: pageSize.value,
      preloadSize: 40,
    })
  }
  function loadStateCounts() {
    if (!onContainersPage.value) {
      return
    }
    containerStore.loadStateCounts(filtersWithoutStates.value)
  }

  function loadByTerminalCounts() {
    if (!onContainersPage.value) {
      return
    }
    containerStore.loadByTerminalCounts(filtersWithoutTerminal.value)
  }
  watch(filtersWithoutTerminal, (newValue, oldValue) => {
    if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
      return // No change - no need to reload
    }
    loadByTerminalCounts()
  })
  watch(filtersWithoutStates, (newValue, oldValue) => {
    if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
      return // No change - no need to reload
    }
    loadStateCounts()
  })
  watch(allFilters, async (newValue, oldValue) => {
    if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
      return // No change - no need to reload
    }
    setPageNoReload(1)
    // Wait for page to be set before loading containers. Just referencing page.value
    // seems to do the trick
    if (page.value !== 1) {
      throw new Error(`Expected page to be 1, but got ${page.value}`)
    }
    loadContainers()
  })

  return {
    assignedToFilter,
    autoBookOnFilter,
    autoBookOffFilter,
    terminalFilter,
    statusFilter,
    customerFilter,
    customerIDFilter,
    containerSearch,
    shippingLineFilter: toRef(filters, 'shippingLineFilter'),
    containerTypeFilter: toRef(filters, 'containerTypeFilter'),
    tagsFilter: toRef(filters, 'tags'),
    vesselsFilter: toRef(filters, 'vessels'),
    updatedInLastMinutesFilter: toRef(filters, 'updatedInLastMinutes'),
    allFilters,
    page,
    pageSize,
    loadByTerminalCounts,
    loadContainers,
    loadStateCounts,
    setAssignedToFilter,
    setAutoBookOnFilter,
    setAutoBookOffFilter,
    setCustomerIDFilter,
    setPage,
    statusFilterLabel,
    statusFilterOptions,
    terminalFilterLabel,
  }
}

export const useContainerFilters = createSharedComposable(_useContainerFilters)
