<script lang="ts" setup>
import { TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN } from '~/constants'
import type { Container } from '~/models/containers'
const props = defineProps<{
  container: Container
}>()
const latest_appointment = computed(() => {
  return props.container.latest_appointment
})
const terminal = computed(() => {
  return props.container.terminal
})
const is_in_import_booked_state = computed(() => {
  return props.container.is_in_import_booked_state
})

const checkInAppointmentDialogOpen = ref(false)
const canCheckInAppointment = computed(() => {
  return (
    terminal.value &&
    TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN.has(terminal.value) &&
    latest_appointment.value?.check_in_status !== true
  )
})
function checkInAppointment() {
  if (!canCheckInAppointment.value) return
  checkInAppointmentDialogOpen.value = true
}

const checkInMessage = computed(() => {
  if (
    is_in_import_booked_state.value &&
    latest_appointment.value &&
    terminal.value &&
    TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN.has(terminal.value)
  ) {
    if (latest_appointment.value.check_in_status === true) {
      return {
        message: '✅ Checked In',
        type: 'info' as 'info' | 'warning',
        tooltip: 'Appointment is checked in.',
      }
    } else {
      return {
        message: '❌ Not Checked In',
        type: 'warning' as 'info' | 'warning',
        tooltip: 'Click to check in.',
      }
    }
  }
  return null
})
</script>

<template>
  <ColoredBadge
    v-if="checkInMessage"
    v-tooltip="checkInMessage.tooltip"
    :type="checkInMessage.type"
    @click.stop="canCheckInAppointment && checkInAppointment()"
  >
    {{ checkInMessage.message }}
    <i-mdi:pencil
      v-if="canCheckInAppointment"
      class="align-text-top h-13px text-gray-400 -mr-1"
    />
    <CheckInAppointmentDialog
      v-if="checkInAppointmentDialogOpen && latest_appointment"
      v-model="checkInAppointmentDialogOpen"
      append-to-body
      :appointment="latest_appointment"
    />
  </ColoredBadge>
</template>
