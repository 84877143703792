<script lang="ts" setup>
import { DateTime } from 'luxon'
import { useContainerFilters } from '~/compositions/containerFilters'
import type { GETFileExport } from '~/services/apiClient'
import {
  AppointmentsApi,
  GETFileExportFileTypeEnum,
} from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'
import { useUserStore } from '~/stores/user'
import { parseContainerNumbers } from '~/utils'

const emit = defineEmits<{ (e: 'close'): void }>()
const userStore = useUserStore()
const containerFilters = useContainerFilters()

// Refs
const dialogOpen = ref(true)
const toTrinium = toRef(userStore, 'exportInTriniumMode')
const exportAllUsingFilters = toRef(userStore, 'exportAllAppointments')
function closeDialog() {
  dialogOpen.value = false
  emit('close')
}

// Stores
const containerStore = useContainerStore()

// Computed properties
const numSelectedContainers = computed(
  () => containerStore.selectedContainerObjects.length
)

// Functions
function exportAppointments(file_type: GETFileExportFileTypeEnum) {
  const apptApi = new AppointmentsApi()
  const now = DateTime.now()
  const fileName = `Appointments Export ${now.toFormat(
    'yyyy-MM-dd HH_mm_ss'
  )}.${file_type}`
  let filters: GETFileExport
  if (exportAllUsingFilters.value) {
    let containerNumbers: string[] | undefined
    if (containerFilters.allFilters.value.container_numbers) {
      containerNumbers = parseContainerNumbers(
        containerFilters.allFilters.value.container_numbers
      )
    }
    filters = {
      ...containerFilters.allFilters.value,
      file_type,
      container_cycle_states: containerFilters.allFilters.value.states,
      terminal: containerFilters.terminalFilter.value || undefined,
      container_numbers: containerNumbers,
    }
  } else {
    const appointment_ids: number[] = []
    for (const container of containerStore.selectedContainerObjects) {
      if (container.latest_appointment) {
        appointment_ids.push(container.latest_appointment.id)
      }
    }
    filters = {
      appointment_ids,
      file_type,
    }
  }
  apptApi
    .fileExportAppointmentsFileExportPost(
      {
        ...filters,
        to_trinium: toTrinium.value,
      },
      { responseType: 'blob' }
    )
    .then((resp) => {
      if (resp.status === 200) {
        const blob = new Blob([resp.data], {
          type: resp.headers['content-type'],
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    })
    .finally(() => {
      closeDialog()
    })
}
</script>

<template>
  <el-dialog
    :model-value="dialogOpen"
    title="Download Appointments"
    width="30%"
    center
    :append-to-body="true"
    @close="emit('close')"
  >
    <div>
      <el-form-item label="Format:">
        <el-switch
          v-model="toTrinium"
          active-text="Trinium"
          inactive-text="Standard"
        />
      </el-form-item>
    </div>
    <div class="mb-6">
      <el-alert
        v-if="toTrinium === true"
        title="About Trinium Format"
        type="info"
        show-icon
        :closable="false"
      >
        Trinium 204 format 646 for appointment upload via CSV.
      </el-alert>
    </div>
    <div>
      <el-form-item>
        <el-radio-group v-model="exportAllUsingFilters">
          <el-radio :label="false" border>
            <span>{{ numSelectedContainers }} Selected</span>
          </el-radio>
          <el-radio :label="true" border>
            <span>All matching filters</span>
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <template #footer>
      <div class="button-holder">
        <el-button
          size="large"
          type="primary"
          @click="exportAppointments(GETFileExportFileTypeEnum.Csv)"
        >
          Download as CSV
        </el-button>
      </div>
      <div class="button-holder">
        <el-button
          size="large"
          type="primary"
          @click="exportAppointments(GETFileExportFileTypeEnum.Xlsx)"
        >
          Download as Excel
        </el-button>
      </div>
      <div class="button-holder">
        <el-button size="large" @click="closeDialog"> Cancel </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.button-holder {
  button {
    width: 100%;
    margin-top: 5px;
  }
}
</style>
