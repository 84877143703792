<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'

import { useUserStore } from '~/stores/user'
import { useGlobalNotificationsStore } from '~/stores/globalNotifications'

import Header from '~/components/navigation/Header.vue'
import LeftNavBarVue from '~/components/navigation/LeftNavBar.vue'
import { contentAreaHeightInjectionKey } from '~/constants'

// Refs
const headerElement = ref<HTMLElement | null>(null)
const notificationsElement = ref<HTMLElement | null>(null)
// Stores
const globalNotificationsStore = useGlobalNotificationsStore()
const userStore = useUserStore()
// Computed
const globalNotificationsToDisplay = computed(() => {
  if (userStore.demo_mode) {
    return []
  } else {
    return globalNotificationsStore.activeNotifications
  }
})
const headerSize = useElementSize(headerElement)
const notificationsSize = useElementSize(notificationsElement)
const windowSize = useWindowSize()

const contentHeight = computed(() => {
  return (
    windowSize.height.value -
    headerSize.height.value -
    notificationsSize.height.value -
    20 // Fudge factor, probably for some padding somewhere
  )
})
provide(contentAreaHeightInjectionKey, contentHeight)
</script>

<template>
  <el-container direction="vertical" class="min-h-full">
    <Header ref="headerElement" />
    <el-container direction="horizontal" class="min-h-full">
      <LeftNavBarVue />
      <el-container class="max-w-full">
        <el-main class="p-1 max-w-full">
          <div ref="notificationsElement">
            <GlobalNotification
              v-for="notification in globalNotificationsToDisplay"
              :key="notification.id"
              :notification="notification"
            />
            <InvalidCredentialsBanner
              v-if="!userStore.isSubCarrier && !userStore.demo_mode"
              class="mb-2 shadow"
            />
          </div>
          <div
            :style="{ height: `${contentHeight}px` }"
            style="overflow-y: auto"
          >
            <router-view />
          </div>
        </el-main>
        <!-- <el-footer class="text-center p-2">
          © 2022 - Nathaniel Brown
        </el-footer> -->
      </el-container>
    </el-container>
  </el-container>
</template>
