<script lang="ts" setup>
import type { AxiosResponse } from 'axios'
import { ElNotification } from 'element-plus'
import { useContainerFilters } from '~/compositions/containerFilters'
import { ContainersApi } from '~/services/apiClient'
import type { POSTAssignmentData } from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'
import { useUsersStore } from '~/stores/users'
const props = defineProps({ unassign: { type: Boolean, required: true } })
const emit = defineEmits<{ (e: 'close'): void }>()
const containerStore = useContainerStore()
const usersStore = useUsersStore()
const {
  assignedToFilter,
  autoBookOnFilter,
  customerFilter,
  statusFilterLabel,
  statusFilter,
  terminalFilterLabel,
  terminalFilter,
  loadContainers,
} = useContainerFilters()
const dialogOpen = ref(true)
const selectedUserIDs = ref([] as number[])
if (props.unassign) {
  selectedUserIDs.value = containerStore.assignedUsersForSelectedContainers.map(
    (user) => user.id
  )
}
const assignedUserString = computed(() => {
  let assignedUsers = null
  if (assignedToFilter.value) {
    if (assignedToFilter.value.includes(-1)) {
      return 'Unassigned'
    }
    assignedUsers = assignedToFilter.value
      .map((id) => {
        return usersStore.users.find((user) => user.id === id)
      })
      .map((user) => {
        return user?.first_name
      })
      .join(', ')
  }
  return assignedUsers
})
const filters = [
  {
    label: 'Terminal',
    value: terminalFilterLabel ? terminalFilterLabel.value : null,
  },
  {
    label: 'Status',
    value: statusFilterLabel ? statusFilterLabel.value : null,
  },
  {
    label: 'Customer',
    value: customerFilter.value?.name ? customerFilter.value.name : null,
  },
  {
    label: 'Auto Book On',
    value: autoBookOnFilter.value ? '✅' : null,
  },
  {
    label: 'Assigned To',
    value: assignedUserString,
  },
]
const isContainersSelected = ref(true)
const verb = computed(() => {
  return props.unassign ? 'Unassign' : 'Assign'
})
const preposition = computed(() => {
  return props.unassign ? 'from' : 'to'
})
function getAssignmentData() {
  let assignmentData = {} as POSTAssignmentData
  if (isContainersSelected.value === false) {
    assignmentData = {
      terminal: terminalFilter.value ?? null,
      container_cycle_states: statusFilter.value?.states?.length
        ? statusFilter.value.states
        : null,
      customer_id: customerFilter.value?.id ?? null,
      auto_book_on: autoBookOnFilter.value ?? null,
      assigned_user_ids: assignedToFilter.value?.length
        ? assignedToFilter.value
        : null,
      // remove any -1s from the list as we cant assign to -1
      user_ids: selectedUserIDs.value.filter((id) => id !== -1),
      container_numbers: undefined,
    } as POSTAssignmentData
  } else {
    assignmentData = {
      container_numbers: containerStore.selectedContainerNumbers,
      user_ids: selectedUserIDs.value.filter((id) => id !== -1),
    }
  }
  return assignmentData
}
function assignUsers() {
  const api = new ContainersApi()
  const assignmentData = getAssignmentData()
  return api.assignContainersContainersAssignPost(assignmentData)
}
function unassignUsers() {
  const api = new ContainersApi()
  const assignmentData = getAssignmentData()
  return api.unassignContainersContainersUnassignPost(assignmentData)
}
const stateCount = computed(() => {
  let count = 0
  for (const state of statusFilter.value?.states ?? []) {
    const stateCount = containerStore.statusCounts.get(state)
    if (stateCount != null) {
      count += stateCount
    }
  }
  return count
})
const saving = ref(false)
function save() {
  let containersLength = containerStore.selectedContainerNumbers.length
  if (!isContainersSelected.value) {
    containersLength = stateCount.value
  }
  saving.value = true
  if (selectedUserIDs.value.length === 0) {
    ElNotification.warning('You must select at lease one user')
    return
  }
  let promise: Promise<AxiosResponse>
  if (props.unassign) {
    promise = unassignUsers()
  } else {
    promise = assignUsers()
  }
  promise
    .then(() => {
      ElNotification.success({
        title: `${verb.value}ed ${
          selectedUserIDs.value.filter((id) => id !== -1).length
        } User${
          selectedUserIDs.value.filter((id) => id !== -1).length > 1 ? 's' : ''
        } ${preposition.value} ${containersLength} Container${
          containersLength > 1 ? 's' : ''
        }`,
      })
      loadContainers()
      emit('close')
    })
    .finally(() => {
      saving.value = false
    })
}
</script>

<template>
  <BaseDialog
    v-model="dialogOpen"
    :title="`${verb} Users`"
    width="40%"
    :close-on-click-modal="false"
    @close="emit('close')"
  >
    <el-form-item :label="`Users to ${verb.toLowerCase()}:`" class="mt-4">
      <UserSelector v-model="selectedUserIDs" />
    </el-form-item>
    <div>
      <el-form-item class="my-2">
        <el-radio-group v-model="isContainersSelected" size="small">
          <el-radio :label="true" border>
            <span
              >{{ containerStore.selectedContainerNumbers.length }} Selected
              Containers</span
            >
          </el-radio>
          <el-radio :label="false" border>
            <span>{{ stateCount }} Containers for Current Filters</span>
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <el-table
      v-if="!isContainersSelected"
      :data="filters"
      style="width: 100%"
      class="my-2"
    >
      <el-table-column label="Current Filters"
        ><el-table-column
          prop="label"
          label="Filter"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="value"
          label="Value"
          show-overflow-tooltip
        ></el-table-column
      ></el-table-column>
    </el-table>
    <template #action-button>
      <el-button
        type="primary"
        size="large"
        :loading="saving"
        :disabled="!selectedUserIDs.filter((id) => id !== -1).length"
        @click="save"
      >
        {{ verb }}
      </el-button>
    </template>
  </BaseDialog>
</template>
