<script lang="ts" setup>
import { Icon } from '@iconify/vue'
import { DateTime } from 'luxon'
import {
  getContainerStateIcon,
  getContainerStateLabel,
  getTerminalLabel,
} from '~/constants'
import { ContainerWatchState, ContainersApi } from '~/services/apiClient'
import { ContainerPhaseGroup } from '~/models/containerChangeGroup'
const props = defineProps({
  containerNumber: { type: String, required: true },
  includeAppointmentChanges: { type: Boolean, default: true },
  includeImportStatusChanges: { type: Boolean, default: true },
  includeGateTransactions: { type: Boolean, default: true },
  includeBookingRequests: { type: Boolean, default: true },
  includeEmptyBookingRequests: { type: Boolean, default: true },
  includeCancellationRequests: { type: Boolean, default: true },
  includeRescheduleRequests: { type: Boolean, default: true },
  includeAddEmptyRequests: { type: Boolean, default: true },
  includeEditChassisRequests: { type: Boolean, default: true },
  includeEditLicensePlateRequests: { type: Boolean, default: true },
  includeCheckInRequests: { type: Boolean, default: true },
  includeAutoBookChanges: { type: Boolean, default: true },
  includeWatchChanges: { type: Boolean, default: true },
  showRawDataChanges: { type: Boolean, default: true },
})
const phaseGroups = ref([] as ContainerPhaseGroup[])
const api = new ContainersApi()

const showRawDataChanges = toRef(props, 'showRawDataChanges')
const loading = ref(false)
// TODO:
function loadContainerHistory() {
  loading.value = true
  phaseGroups.value = []
  api
    .getContainerHistoryGroupedContainersContainerNumberHistoryGroupedGet(
      props.containerNumber,
      props.showRawDataChanges,
      props.includeAppointmentChanges,
      props.includeImportStatusChanges,
      props.includeGateTransactions,
      props.includeBookingRequests,
      props.includeEmptyBookingRequests,
      props.includeCancellationRequests,
      props.includeRescheduleRequests,
      props.includeAddEmptyRequests,
      props.includeEditLicensePlateRequests,
      props.includeEditChassisRequests,
      props.includeCheckInRequests,
      props.includeAutoBookChanges,
      props.includeWatchChanges
    )
    .then((resp) => {
      phaseGroups.value = resp.data.map(
        (group) => new ContainerPhaseGroup(group)
      )
      // Set .left_phase attribute to the previous group's .entered_phase
      for (let i = 1; i < phaseGroups.value.length; i++) {
        phaseGroups.value[i].left_phase = phaseGroups.value[i - 1].entered_phase
      }
      loading.value = false
    })
}
onMounted(loadContainerHistory)
watch(showRawDataChanges, () => {
  if (showRawDataChanges) {
    loadContainerHistory()
  }
})

function terminalStatusPreposition(status: ContainerWatchState): string {
  switch (status) {
    case ContainerWatchState.ImportPickedUp:
      return 'from'
    default:
      return 'at'
  }
}
</script>

<template>
  <div v-loading="loading" class="min-h-12">
    <div
      v-for="group in phaseGroups"
      :key="group.entered_phase.toISO()"
      class="bg-gray-50 rounded-4px border px-4 pt-2 mt-2"
    >
      <div class="mb-4">
        <h3 class="text-md font-bold">
          <Icon
            v-tooltip="`Container state: ${group.cycle_state}`"
            :icon="getContainerStateIcon(group.cycle_state)"
            class="align-middle text-xl"
          />
          {{ getContainerStateLabel(group.cycle_state) }}
          <template v-if="group.terminal">
            {{ terminalStatusPreposition(group.cycle_state) }}
            {{ getTerminalLabel(group.terminal) }}
          </template>

          for {{ group.durationDescription }}
        </h3>
        <div class="text-gray-400" style="font-size: 13px">
          Starting
          {{ group.entered_phase.toLocaleString(DateTime.DATETIME_MED)
          }}<template v-if="group.left_phase"
            >, ending
            {{ group.left_phase.toLocaleString(DateTime.DATETIME_MED) }}
          </template>
        </div>
      </div>
      <el-timeline v-if="group.changes.length > 0" class="min-h-14 pl-1">
        <ChangeGroup
          v-for="changeGroup in group.changes"
          :key="changeGroup.observed.toISO()"
          :change-group="changeGroup"
          :show-raw-changes="showRawDataChanges"
        />
      </el-timeline>
    </div>
  </div>
</template>
