<script lang="ts" setup>
import { Crisp } from 'crisp-sdk-web'
import { ElMessage } from 'element-plus'
import type { FormInstance } from 'element-plus/lib/components'

import { getTerminalLabel } from '~/constants'
import { getOpenReplaySessionURL } from '~/observability/openReplay'
import type { TerminalName } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'

const props = defineProps<{
  containerNumber: string
  terminal: TerminalName | null
}>()

const dialogOpen = ref(false)
const formRef = ref<FormInstance>()
enum Urgency {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}
const form = reactive({
  issueDesc: '',
  issueUrgency: Urgency.Medium,
})

const userStore = useUserStore()

const terminalDesc = computed(() => {
  if (props.terminal) {
    return getTerminalLabel(props.terminal)
  }
  return 'Unknown terminal'
})

function reportIssue() {
  dialogOpen.value = false
  Crisp.chat.open()
  const scac = userStore.userInfo?.current_organization.scac
  const companyName = userStore.userInfo?.current_organization.name
  const openReplayURL = getOpenReplaySessionURL()
  const containerLink =
    `https://app.draydog.com/containers?selected_container=${props.containerNumber}` +
    `&containers=${props.containerNumber}&scac=${scac}`
  const issueData: any = {
    containerNumber: props.containerNumber,
    terminal: props.terminal,
    description: form.issueDesc,
    urgency: form.issueUrgency,
  }
  if (openReplayURL) issueData.openReplayURL = openReplayURL
  if (companyName) issueData.companyName = companyName
  if (scac) issueData.companySCAC = scac
  Crisp.session.setSegments(['container-issue'], false)
  Crisp.session.pushEvent('container-issue', issueData)
  Crisp.message.send(
    'text',
    `Hello, I am having an issue with container ` +
      `[${props.containerNumber}](${containerLink}) at ${terminalDesc.value}`
  )
  Crisp.message.send('text', form.issueDesc)
}

function handleSubmit() {
  formRef.value?.validate((valid, fields) => {
    if (valid) {
      reportIssue()
    } else {
      ElMessage.error('Please fill in the form')
    }
  })
}

const formRules = reactive({
  issueDesc: [
    {
      required: true,
      message: 'Please describe your issue',
      trigger: 'change',
    },
    {
      min: 6,
      message: 'Minimum length is 6 characters',
      trigger: 'blur',
    },
  ],
})
</script>

<template>
  <el-button
    v-tooltip="`Report an issue with this container`"
    size="small"
    plain
    type="danger"
    v-bind="$attrs"
    @click="dialogOpen = true"
  >
    <i-mdi:bug /> Report Issue
  </el-button>

  <BaseDialog v-model="dialogOpen">
    <template #header>
      <h2 class="text-xl">Report issue for {{ props.containerNumber }}</h2>
    </template>
    <el-form
      ref="formRef"
      label-position="top"
      :rules="formRules"
      :model="form"
    >
      <el-form-item label="Please describe your issue:" prop="issueDesc">
        <el-input
          v-model="form.issueDesc"
          :rows="3"
          type="textarea"
          placeholder="Example: LFD is says 12/2 but it should be 12/5"
        />
      </el-form-item>
      <div class="flex">
        <el-form-item label="Container Number:" class="mr-4">
          <el-input
            :model-value="props.containerNumber"
            disabled
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="Terminal:">
          <el-input :model-value="terminalDesc" disabled style="width: 200px" />
        </el-form-item>
      </div>
      <el-form-item label="How urgent is this issue for you?">
        <el-radio-group v-model="form.issueUrgency" label="label position">
          <el-radio :label="Urgency.High" border>🔥 Urgent </el-radio>
          <el-radio :label="Urgency.Medium" border>
            🤷I'd like it looked into
          </el-radio>
          <el-radio :label="Urgency.Low" border>
            🗒️ Not urgent, just letting you know
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-alert type="info" :closable="false">
      📸 You can add screenshots/files via the chat once reported
    </el-alert>
    <template #action-button>
      <el-button
        size="large"
        type="primary"
        class="w-full"
        @click="handleSubmit"
      >
        <i-mdi:bug /> Report Issue (opens chat)
      </el-button>
    </template>
  </BaseDialog>
</template>
