import type { DateTime } from 'luxon'
import {
  shiftWindowEnd,
  shiftWindowStart,
  shortShiftName,
  sortableShiftKey,
} from '~/logic/shifts'
import { MoveType } from '~/models/appointments'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { createAppointmentWithContainerInfo } from '~/models/fixtureFactories'
import type { GETAppointmentWithContainer, Shift } from '~/services/apiClient'

export function makeDayGroupKey(date: DateTime): string {
  return date.toFormat('yyyy-MM-dd')
}

export class DayGroup {
  key: string
  date: DateTime
  shiftGroups: ShiftGroup[]
  numAppointments: number

  constructor(date: DateTime) {
    this.key = makeDayGroupKey(date)
    this.date = date
    this.shiftGroups = []
    this.numAppointments = 0
  }

  addShiftGroup(shiftGroup: ShiftGroup) {
    if (this.key !== makeDayGroupKey(shiftGroup.date)) {
      throw new Error(
        `ShiftGroup with date ${shiftGroup.date} (key: ${shiftGroup.key}) does not match day group date: ${this.date} (key: ${this.key})`
      )
    }
    this.shiftGroups.push(shiftGroup)
    this.numAppointments += shiftGroup.countTotal
  }
}

export class ShiftGroup {
  key: string
  shift: Shift
  date: DateTime
  transactions: AppointmentTransaction[]
  countTotal: number
  countLoadOut: number
  countEmptyIn: number
  windowStart: DateTime
  windowEnd: DateTime

  constructor(shiftDate: DateTime, shift: Shift) {
    this.key = sortableShiftKey(shiftDate, shift)
    this.shift = shift
    this.date = shiftDate
    this.transactions = []
    this.countTotal = 0
    this.countLoadOut = 0
    this.countEmptyIn = 0
    this.windowStart = shiftWindowStart(shiftDate, shift)
    this.windowEnd = shiftWindowEnd(shiftDate, shift)
  }

  get shortShiftName(): string {
    return shortShiftName(this.shift)
  }

  addAppointment(transaction: AppointmentTransaction) {
    this.transactions.push(transaction)
    this.updateCountsForTransaction(transaction)
  }

  updateCountsForTransaction(transaction: AppointmentTransaction) {
    if (transaction.inbound_appointment) {
      this.updateCountsForAppointment(transaction.inbound_appointment)
    } else if (transaction.outbound_appointment) {
      this.updateCountsForAppointment(transaction.outbound_appointment)
    }
  }

  updateCountsForAppointment(appointment: AppointmentWithContainerInfo) {
    this.countTotal++
    if (appointment.appointment.moveType === MoveType.LoadOut) {
      this.countLoadOut++
    } else if (appointment.appointment.moveType === MoveType.EmptyIn) {
      this.countEmptyIn++
    }
    // A little hack to places these checks in this method, but it's convenient
    if (!appointment.appointment.shiftDate.equals(this.date)) {
      throw new Error(
        `Appointment with shift date ${appointment.appointment.shiftDate} ` +
          `(key: ${appointment.appointment.scheduledShift}) does not match shift date:` +
          ` ${this.date} (key: ${this.key})`
      )
    }
    if (appointment.appointment.shift !== this.shift) {
      throw new Error('Appointment does not match shift')
    }
  }

  sortAppointments() {
    this.transactions.sort((a, b) => {
      return a.window_start.diff(b.window_start).as('seconds')
    })
  }

  sortComparison(other: ShiftGroup): -1 | 0 | 1 {
    if (this.key < other.key) return -1
    if (this.key > other.key) return 1
    return 0
  }
}

export class AppointmentTransaction {
  inbound_appointment: AppointmentWithContainerInfo | undefined
  outbound_appointment: AppointmentWithContainerInfo | undefined
  constructor() {
    this.inbound_appointment = undefined
    this.outbound_appointment = undefined
  }

  get primary_appointment() {
    if (this.outbound_appointment) {
      return this.outbound_appointment
    } else if (this.inbound_appointment) {
      return this.inbound_appointment
    }
    throw new Error('No appointment, this should not be!')
  }

  get window_start() {
    return this.primary_appointment.appointment.window_start
  }

  get sortable_shift_key() {
    return sortableShiftKey(
      this.primary_appointment.appointment.shiftDate,
      this.primary_appointment.appointment.shift
    )
  }

  get shiftDate() {
    return this.primary_appointment.appointment.shiftDate
  }

  get shift() {
    return this.primary_appointment.appointment.shift
  }

  get terminal() {
    return this.primary_appointment.appointment.terminal
  }

  get terminal_name() {
    return this.primary_appointment.appointment.terminal_name
  }

  get window_description() {
    return this.primary_appointment.appointment.window_description
  }

  get customerName() {
    // Of course, the inbound and outbound customers may be different. I'll just do
    // outbound for starters
    if (this.outbound_appointment) {
      return this.outbound_appointment.watch.customer_name
    }
    return undefined
  }

  get customerId() {
    if (this.outbound_appointment) {
      return this.outbound_appointment.watch.customer_id
    }
    return undefined
  }
}

export function createAppointmentTransaction({
  inbound_appointment,
  outbound_appointment,
}: {
  inbound_appointment?: Partial<GETAppointmentWithContainer>
  outbound_appointment?: Partial<GETAppointmentWithContainer>
}): AppointmentTransaction {
  const transaction = new AppointmentTransaction()
  if (inbound_appointment) {
    transaction.inbound_appointment =
      createAppointmentWithContainerInfo(inbound_appointment)
  }
  if (outbound_appointment) {
    transaction.outbound_appointment =
      createAppointmentWithContainerInfo(outbound_appointment)
  }
  return transaction
}
