<script lang="ts" setup>
import type { PropType } from 'vue'
import { PreferenceOption } from '~/services/apiClient/api'

const props = defineProps({
  modelValue: { type: String as PropType<PreferenceOption>, required: true },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: PreferenceOption): void
}>()
const preference = useVModel(props, 'modelValue', emit)

function timeOfDayOptionColor(option: PreferenceOption) {
  switch (option) {
    case PreferenceOption.NotAllowed:
      return '#F56C6C'
    case PreferenceOption.Low:
      return '#95d475'
    case PreferenceOption.Medium:
      return '#67C23A'
    case PreferenceOption.High:
      return '#529b2e'
    default:
      return '#409EFF'
  }
}
</script>

<template>
  <el-radio-group
    v-model="preference"
    size="small"
    :fill="timeOfDayOptionColor(preference)"
  >
    <el-radio-button :label="PreferenceOption.NotAllowed">
      Not Allowed
    </el-radio-button>
    <el-radio-button :label="PreferenceOption.Low"> Low </el-radio-button>
    <el-radio-button :label="PreferenceOption.Medium"> Medium </el-radio-button>
    <el-radio-button :label="PreferenceOption.High"> High </el-radio-button>
  </el-radio-group>
</template>
