<script lang="ts" setup>
import type { FormInstance, FormRules } from 'element-plus'
import { SELF_SELECTABLE_USER_ROLES } from '~/constants'
import type { UserRoleType } from '~/services/apiClient'
import { UsersApi } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'

const emit = defineEmits<{ (e: 'signupComplete', role: UserRoleType): void }>()

const userStore = useUserStore()
const usersApi = new UsersApi()
const route = useRoute()

const ruleFormRef = ref<FormInstance>()
const form = reactive({
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  token: '',
  role: '',
})
const rules = reactive<FormRules>({
  first_name: [{ required: true, message: 'First name is required' }],
  last_name: [{ required: true, message: 'Last name is required' }],
  password: [
    { required: true, message: 'Password is required' },
    { min: 6, message: 'Password must be at least 6 characters' },
  ],
  role: [{ required: true, message: 'Role is required' }],
})
onMounted(() => {
  form.email = route.query.email as string
  form.token = route.query.token as string
  form.role = route.query.role as string
})
const roleOptions: { label: UserRoleType }[] = SELF_SELECTABLE_USER_ROLES.map(
  (role) => ({ label: role })
)
const signingUp = ref(false)
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid) => {
    if (valid) {
      signingUp.value = true
      usersApi
        .activateUserUsersActivatePost({
          token: form.token,
          first_name: form.first_name,
          last_name: form.last_name,
          password: form.password,
          role: form.role as UserRoleType,
        })
        .then((resp) => {
          userStore.logout()
          userStore.login(resp.data)
          emit('signupComplete', form.role as UserRoleType)
        })
        .finally(() => {
          signingUp.value = false
        })
    }
  })
}
</script>

<template>
  <el-form ref="ruleFormRef" label-width="98px" :rules="rules" :model="form">
    <el-form-item label="Email:" prop="email">
      <el-input v-model="form.email" name="email" disabled />
    </el-form-item>
    <el-form-item label="First Name:" prop="first_name">
      <el-input v-model="form.first_name" name="first_name" />
    </el-form-item>
    <el-form-item label="Last Name:" prop="last_name">
      <el-input v-model="form.last_name" name="last_name" />
    </el-form-item>
    <el-form-item label="Password:" prop="password">
      <el-input
        v-model="form.password"
        type="password"
        name="password"
        show-password
      />
    </el-form-item>
    <el-form-item label="Role:" prop="role">
      <el-select
        v-model="form.role"
        placeholder="Select Role"
        class="w-full"
        :disabled="Boolean(route.query.role)"
      >
        <el-option
          v-for="roleOption in roleOptions"
          :key="roleOption.label"
          :label="roleOption.label"
          :value="roleOption.label"
        />
      </el-select>
    </el-form-item>
    <el-button
      v-loading="signingUp"
      type="primary"
      class="w-full mt-4"
      @click="submitForm(ruleFormRef)"
    >
      Sign Up
    </el-button>
  </el-form>
</template>
