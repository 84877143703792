<script lang="ts" setup>
import {
  ElMessage,
  ElMessageBox,
  type FormInstance,
  type FormRules,
} from 'element-plus'
import {
  type PendingOrganization,
  type UserRoleType,
  UsersApi,
} from '~/services/apiClient'
import { SELF_SELECTABLE_USER_ROLES } from '~/constants'
import { useUserStore } from '~/stores/user'

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
  pendingRoles: {
    type: Array as PropType<PendingOrganization[]>,
    required: true,
  },
  userRole: {
    type: String as PropType<UserRoleType>,
    default: undefined,
  },
})

const ruleFormRef = ref<FormInstance>()
const router = useRouter()
const form = reactive({
  role: props.userRole,
  token: props.token,
})
const roleOptions: { label: UserRoleType }[] = SELF_SELECTABLE_USER_ROLES.map(
  (role) => ({ label: role })
)
const rules = reactive<FormRules>({
  role: [{ required: true, message: 'Role is required' }],
})
const accepting = ref(false)
const userStore = useUserStore()
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  try {
    await formEl.validate()
  } catch (err) {
    ElMessage.error('Please fix form errors')
    return
  }

  const usersApi = new UsersApi()
  accepting.value = true
  let resp
  try {
    resp = await usersApi.acceptInvitesUsersAcceptInvitesPost({
      token: form.token,
      organization_roles: props.pendingRoles.map((role) => {
        return {
          organization_id: role.org_id,
          role: form.role as UserRoleType,
        }
      }),
    })
  } finally {
    accepting.value = false
  }
  userStore.addAuthHeader(resp.data)
  await userStore.switchToOrg(resp.data.organization_id)
  await userStore.loadInfo()
  const title = `About Dray Dog multi-org support`
  const msg =
    `You are now a member of multiple organizations. ` +
    ` To swap to a different org, use the menu in the upper right hand corner of this window`
  await ElMessageBox.alert(msg, title, {
    confirmButtonText: 'I Understand',
    type: 'info',
    showClose: false,
    closeOnClickModal: false,
    closeOnPressEscape: false,
  })
  router.push({ name: 'containers' })
}
</script>

<template>
  <el-form ref="ruleFormRef" :rules="rules" :model="form" label-position="top">
    <div>
      You've been invited to join the following Dray Dog Accounts:
      <ul class="mt-2 mb-10">
        <li v-for="role in pendingRoles" :key="role.org_id">
          {{ role.name }}
        </li>
      </ul>
    </div>
    <el-form-item label="Your role at these organizations:" prop="role">
      <el-select
        v-model="form.role"
        placeholder="Select Role"
        class="w-full"
        :disabled="Boolean(props.userRole)"
      >
        <el-option
          v-for="roleOption in roleOptions"
          :key="roleOption.label"
          :label="roleOption.label"
          :value="roleOption.label"
        />
      </el-select>
    </el-form-item>
    <el-button
      v-loading="accepting"
      type="primary"
      class="w-full mt-6"
      @click="submitForm(ruleFormRef)"
    >
      Accept Invite<template v-if="pendingRoles.length > 1">s</template>
    </el-button>
  </el-form>
</template>

<style scoped>
li {
  margin-left: 2em;
  list-style-type: disc;
}
</style>
