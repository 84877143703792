<script lang="ts" setup>
import { useElementHover } from '@vueuse/core'
import type { Container } from '~/models/containers'
import { useContainerStore } from '~/stores/containers'
const props = defineProps<{
  container: Container
}>()
const container = toRef(props, 'container')
const containerStore = useContainerStore()
const containerRowHovered = ref()
const isHovered = useElementHover(containerRowHovered)
const focusedContainer = toRef(containerStore, 'focusedContainer')
function checkIfIsSelected(): boolean {
  const selected = containerStore.isContainerSelected(container.value.number)
  return selected
}
const isSelected = ref(checkIfIsSelected())
const selectKeyRef = toRef(containerStore, 'selectionKey')

watch([container, selectKeyRef], () => {
  isSelected.value = checkIfIsSelected()
})
</script>

<template>
  <div
    ref="containerRowHovered"
    :class="{
      'bg-blue-100': container.number === focusedContainer?.number,
    }"
    class="flex justify-between p-2 cursor-pointer border-t-1"
    :style="{ height: `${container.rowHeight}px` }"
    @click="containerStore.focusedContainer = container"
  >
    <!-- Checkbox -->
    <div class="pr-2 flex items-start -mt-1">
      <!-- Without the :key, we get "flashes" of selected checkboxes as you scroll
       because it takes some time for the watcher to update isSelected and rerender -->
      <el-checkbox
        :key="container.key"
        :model-value="isSelected"
        @click.prevent.stop="
          () => containerStore.toggleContainerSelection(container.number)
        "
      />
    </div>
    <div class="flex-1">
      <!-- Top Row (container info / container status) -->
      <div class="info-row">
        <div class="left-side">
          <ContainerDescription :container="container" />
          <ContainerRowAutoBookIcon :container="container" />
        </div>
        <div class="right-side">
          <ContainerStatus :container="container" />
        </div>
      </div>
      <!-- Middle row (badges / appointment info) -->
      <div class="info-row">
        <div class="left-side flex-1 relative">
          <ContainerBadges
            v-if="container.badges.length > 0"
            :container="container"
            class="container-badges"
          />
        </div>
        <div class="right-side">
          <CheckInAppointmentButton class="mr-1" :container="container" />
          <AppointmentOrGateTimesInfo :container="container" />
          <template
            v-if="container.is_in_booked_state && container.booked_appointment"
          >
            •
            <EditLicensePlateButton :container="container" />
            <EditChassisButton
              v-if="
                container.booked_appointment &&
                props.container.booked_appointment?.own_chassis_description
              "
              :container="container"
            />
          </template>
        </div>
      </div>
      <!-- Bottom row (customer + assignee / LFD) -->
      <div class="info-row">
        <ContainerRowCustomerAndUsers
          :container="container"
          class="left-side"
        />
        <div class="right-side">
          <!-- Action items -->
          <ContainerRowActionItems
            :is-row-hovered="isHovered"
            :container="container"
          />
          <span
            v-if="container.shouldHaveLFD && container.import_status"
            class="text-sm inline-block ml-1"
          >
            LFD:
            <LFD
              :container="container"
              :import-status="container.import_status"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
/* doing this to align with lfd badge size */
.action-buttons button {
  max-height: 20px;
  margin-left: 2px;
  size: small;
  padding: 2px 5px;
}
.info-row {
  @apply flex justify-between relative;
}
.right-side {
  @apply text-right;
}
</style>
