import OpenReplay from '@openreplay/tracker'
import * as Sentry from '@sentry/vue'
import trackerAssist from '@openreplay/tracker-assist'
import { InputMode } from '@openreplay/tracker/lib/modules/input'
import { setOpenReplaySessionURLForCrispChat } from '~/services/crispChat'

export const openReplaySessionURLKeyName = 'openReplaySessionURL'

let startTrackingTime = Date.now()
const tracker = new OpenReplay({
  projectKey: '3AEaGMaU9GOFOWqvbvVB',
  network: {
    capturePayload: true,
    sessionTokenHeader: 'X-OpenReplay-SessionToken',
    failuresOnly: false,
    ignoreHeaders: ['Cookie', 'Set-Cookie', 'Authorization'],
    captureInIframes: false,
  },
  obscureInputDates: false,
  obscureInputEmails: false,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputNumbers: false,
  defaultInputMode: InputMode.Plain,
  onStart: ({ sessionToken }) => {
    startTrackingTime = Date.now()
    // For Server-side linking
    Sentry.setTag('openReplaySessionToken', sessionToken)
    // Puts link to session recording on client side errors
    // This is also set at error time so we have a good "jump to" time
    const sessionURL = getOpenReplaySessionURL()
    if (sessionURL) {
      Sentry.setTag(openReplaySessionURLKeyName, sessionURL)
      setOpenReplaySessionURLForCrispChat(sessionURL)
    }
  },
  __DISABLE_SECURE_MODE: true,
})
// https://docs.openreplay.com/en/plugins/assist/
tracker.use(trackerAssist({}))
tracker.stop()

export function getOpenReplayTracker(): OpenReplay {
  return tracker
}
export function getOpenReplaySessionURL(): undefined | string {
  let sessionURL = tracker.getSessionURL({ withCurrentTime: true })
  if (sessionURL) {
    const trackingDurationMilliseconds = Date.now() - startTrackingTime
    sessionURL += `?jumpto=${trackingDurationMilliseconds}`
    // They messed up redirects or something at some point. This fixes it, they'll
    // probably fix at some point I would imagine
    return sessionURL.replace(
      'https://openreplay.com',
      'https://app.openreplay.com'
    )
  }
}
